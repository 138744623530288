const colorPrimary = '#3881EE'
const colorPrimaryDark = '#09467D'
const colorPrimaryLight = '#C4E3FF'
const colorLight = '#FFF'
const colorNeutralLight = '#EEE'
const colorNeutral = '#CCC'
const colorNeutralDark = '#AAA'
const colorDark = '#09467D'

const colorPositive = '#4CE0A5'
const colorPositiveDark = '#28A472'
const colorNegative = '#DC3E15'

const colorBlack = '#49515C'
const colorOutlook = '#DC3E15'
const colorGoogle = '#4285F4'

const colorApple = '#00000'
const colorExchange = '#046CB2'

export {
  colorBlack,
  colorPrimary,
  colorPrimaryDark,
  colorPrimaryLight,
  colorLight,
  colorNeutralLight,
  colorNeutral,
  colorNeutralDark,
  colorPositive,
  colorPositiveDark,
  colorNegative,
  colorDark,
  colorOutlook,
  colorGoogle,
  colorExchange,
  colorApple
}
